#galleria-loader{height:1px!important}

.galleria-theme-folio {
    position:relative;
    font-family:arial,sans-serif;
    min-height: 18px;
}
.galleria-theme-folio img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.galleria-theme-folio .galleria-stage {
    background:#000;
    top:0; bottom:0; left:0; right:0;
    visibility: hidden;
    position: absolute;
    overflow: hidden;
}

.galleria-theme-folio .galleria-thumbnails {
  -webkit-transition: left 200ms ease-out;
  transition: left 200ms ease-out;
}

.galleria-theme-folio .galleria-thumbnails-container {
  position:absolute;
  left:0;
  right:0;
  top:0;
}

.galleria-theme-folio .galleria-thumbnails-list {
  position: absolute;
  left: 0;
  width: 100%;
}

.galleria-theme-folio .galleria-thumbnails .galleria-image {
    width: 190px;
    height: 120px;
    margin: 0 10px 10px 0;
    position:absolute!important;
    top:0;
    left:0;
    cursor: pointer; /* IE */
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    background:#eee url(miniloader.gif) no-repeat 50% 50%;
}

.galleria-theme-folio.dark .galleria-thumbnails .galleria-image {
    background:#222 url(miniloader-neg.gif) no-repeat 50% 50%;
}

.galleria-theme-folio .galleria-loader {
    width:64px;
    height:64px;
    display:none;
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-16px;
    margin-top:-16px;
    background:#000 url(loader.gif) no-repeat 50% 50%;
    z-index:3;
    opacity:.5;
    filter: alpha(opacity=50);
    border-radius:5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.galleria-theme-folio .galleria-info {
    display:none;
    background:#000;
    color:#fff;
    padding:8px 0;
    z-index:3;
    position:absolute;
    bottom:10px;
    background:rgba(0,0,0,0.7);
    font-size:12px;
    line-height:16px;
    max-width:50%;
    -webkit-transition: all 100ms;
}

.galleria-theme-folio .galleria-info-text {
    padding: 0 12px;
}

.galleria-theme-folio .galleria-info-description {
    color:#bbb;
}

:root *> .galleria-theme-folio .galleria-info-description {
    color:rgba(255,255,255,0.6);
}

.galleria-theme-folio .galleria-plus {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background:url(bg.png);
    color:#fff;
    line-height:1.2;
    font-size:11px;
    display:none;
}
.galleria-theme-folio .galleria-plus span {
    position:absolute;
    bottom:5px;
    left:5px;
    background:#000;
    background:rgba(0,0,0,.5);
    padding: 1px 5px;
    max-width:160px;
}

.galleria-theme-folio .galleria-close {
    width:50px;
    height:50px;
    position:absolute;
    top:0;
    right:0;
    background:url(close.png) no-repeat 0 0;
    z-index:100;
    cursor:pointer;
}

.galleria-theme-folio .galleria-close:hover{
    background-position:0 -50px;
}

.galleria-theme-folio .galleria-image-nav {
    width:100%;
    height:auto;
    position:absolute;
    top:80px;
    bottom:80px;
    left:0;
}
.galleria-theme-folio.touch .galleria-image-nav {
    display: none;
}
.galleria-theme-folio .galleria-image-nav-left,
.galleria-theme-folio .galleria-image-nav-right {
    width:100px;
    height:100%;
    background:url(left.png) no-repeat 0 50%;
    position:absolute;
    left:0;
    z-index:3;
    cursor:pointer;
}
.galleria-theme-folio .galleria-image-nav-right {
    left:auto;
    right:0;
    background:url(right.png) no-repeat 100% 50%;
}

.galleria-theme-folio .galleria-image-nav-left:hover{background-image:url(left-hover.png);}
.galleria-theme-folio .galleria-image-nav-right:hover{background-image:url(right-hover.png);}

.galleria-theme-folio .galleria-image-nav-left.cur{cursor:url(left-cursor.png),pointer;background:none!important}
.galleria-theme-folio .galleria-image-nav-right.cur{cursor:url(right-cursor.png),pointer;background:none!important}

.galleria-theme-folio.videoplay .galleria-info { display:none!important; }

.galleria-theme-folio.iframe .galleria-image-nav-left,
.galleria-theme-folio.iframe .galleria-image-nav-left.cur{cursor:pointer;background:url(left.png) no-repeat 0 50%!important;width:100px;}
.galleria-theme-folio.iframe .galleria-image-nav-right,
.galleria-theme-folio.iframe .galleria-image-nav-right.cur{cursor:pointer;background:url(right.png) no-repeat 100% 50%!important;width:100px}

.galleria-theme-folio.iframe .galleria-image-nav-left:hover,
.galleria-theme-folio.iframe .galleria-image-nav-left.cur:hover{background-image:url(left-hover.png)!important;}
.galleria-theme-folio.iframe .galleria-image-nav-right:hover,
.galleria-theme-folio.iframe .galleria-image-nav-right.cur:hover{background-image:url(right-hover.png)!important;}

.galleria-theme-folio .galleria-preloader{height:4px;width:180px;background:#eee;background:rgba(255,255,255,.1);position:absolute;top:0;}
.galleria-theme-folio.center .galleria-preloader{left:50%;margin-left:-90px;}
.galleria-theme-folio .galleria-loaded{height:4px;width:0%;background:#444;background:rgba(0,0,0,.8);}
